import { useEffect } from "react";
import Helmet from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import NotFound from "./pages/404";
import About from "./pages/about";
import AppLanding from "./pages/appLanding";
import Blog from "./pages/blog";
import BlogArticle from "./pages/blogArticle";
import Consulting from "./pages/consulting";
import Contact from "./pages/contact";
import FAQs from "./pages/FAQs";
import Home from "./pages/home";
import Landing1 from "./pages/landing1";
import Landing2 from "./pages/landing2";
import Landing3 from "./pages/landing3";
import PersonalPortfolio from "./pages/personalPortfolio";
import Pricing from "./pages/pricing";
import ProjectDetails from "./pages/projectDetails";
import Projects from "./pages/projects";
import SaasLanding from "./pages/saasLanding";
import Services from "./pages/services";
import { Subscription } from "./pages/Subscription";
import WhatsAppButton from "./components/btnWhatsApp";

function Stact() {
  return (
    <>
      <Helmet>
        <title>KiVenda</title>
        <meta
          name="description"
          content="KiVenda é uma plataforma de vendas online que permite a qualquer pessoa criar uma loja virtual e começar a vender seus produtos na internet."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://www.kivenda.com" />
        <meta property="og:locale" content="pt_BR" />
        <meta
          property="og:image"
          content="https://www.kivenda.com/kirango.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.kivenda.com/kirango.png"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="KiVenda - Sua loja virtual em minutos"
        />
        <meta
          property="og:description"
          content="KiVenda é uma plataforma de vendas online que permite a qualquer pessoa criar uma loja virtual e começar a vender seus produtos na internet."
        />
        <meta property="og:url" content="https://www.kivenda.com" />
        <meta
          property="og:site_name"
          content="KiVenda - Sua loja virtual em minutos"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://www.kivenda.com/kirango.png"
        />
        <meta
          name="twitter:title"
          content="KiVenda - Sua loja virtual em minutos"
        />
        <meta
          name="twitter:description"
          content="KiVenda é uma plataforma de vendas online que permite a qualquer pessoa criar uma loja virtual e começar a vender seus produtos na internet."
        />
      </Helmet>
      <div className="stact-container">
        <Router>
          <WhatsAppButton />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Landing1 />} />
            <Route path="/assinar" element={<Subscription />} />
            <Route path="/contact" element={<Contact />} />

            {/* <Route path='/landing-2' element={<Landing2/>} />
              <Route path='/landing-3' element={<Landing3/>} />
              <Route path='/app-landing' element={<AppLanding/>} />
              <Route path='/saas-landing' element={<SaasLanding/>} />
              <Route path='/personal-portfolio' element={<PersonalPortfolio/>} />
              <Route path='/consulting' element={<Consulting/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/pricing' element={<Pricing/>} />
              <Route path='/faqs' element={<FAQs/>} />
              <Route path='/projects' element={<Projects/>} />
              <Route path='/project-detail' element={<ProjectDetails/>} />
              <Route path='/blog' element={<Blog/>} />
              <Route path='/blog-article' element={<BlogArticle/>} />
              <Route path='/services' element={<Services/>} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default Stact;
